
// var mySocket = {};

export var mySocket = function( port ){
	// console.log( "Socket initialized." );

	if( location.hostname === "localhost" ){
		return new WebSocket("ws://localhost:" + port + "/");
	}
	if( location.protocol === "http:" ){
		return new WebSocket("ws://"+ location.hostname +":" + location.port + "/wsapp/" );
	}
	return new WebSocket("wss://"+ location.hostname +":" + location.port + "/wsapp/" );
};

