

<template>
	<div class="container">
		<div class="app-title">
			<a class="header__logo-link" href="https://www.karavantrailers.com" target="_blank">
				<svg class="header__logo-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.38 26.89">
					<path data-name="Path 421" d="M21.17 11.52L32.5 0h12.68l-12.8 12.38.86 14.5H19.78l.08-11.06H17.4L12.52 26.9H0L11.9 0h12.52l-5.13 11.52z"/>
					<path data-name="Path 422" d="M106.35 15.94a3.91 3.91 0 0 1-2.14 1.64 11.36 11.36 0 0 1-4.43.74l2.63 8.57H89.77l-2.14-8.57h-1.6l-3.77 8.57H69.99L81.89 0h23.27c2.87 0 4.84.33 5.86.94a1.84 1.84 0 0 1 .87 2.54zM92.07 4.71l-4.1 9.27h5.99c.78 0 1.27-.2 1.43-.58l3.62-8.15c.16-.37-.17-.58-.95-.58h-5.99z"/>
					<path data-name="Path 423" d="M167.6 0h12.43L156.8 26.89h-12.76L144.62 0h12.43l-.41 3.44-2.01 13.9 10.42-13.98z"/>
					<path data-name="Path 424" d="M210.85 0h11.7l1.47 10.78L228.78 0h12.6l-11.9 26.89h-12.6l-1.64-11.23-4.97 11.23h-11.32z"/>
					<path data-name="Path 425" d="M57 0L34.02 26.89h12.47c7.47-1.72-1.35-6.15-1.35-6.15s10.5 2.25 10.59 2.3c5.29 1.48.82 3.85.82 3.85H69.3L70.13 0zm.86 19.26H52.2l7.1-9.5z"/>
					<path data-name="Path 426" d="M126.45 0l-22.94 26.89H116c7.47-1.72-1.36-6.15-1.36-6.15s10.51 2.25 10.6 2.3c5.29 1.48.81 3.85.81 3.85h12.76L139.62 0zm.86 19.26h-5.66l7.1-9.5z"/>
					<path data-name="Path 427" d="M186.08 0L163.1 26.89h12.47c7.47-1.72-1.35-6.15-1.35-6.15s10.5 2.25 10.59 2.3c5.29 1.48.82 3.85.82 3.85h12.76L199.2 0zm.86 19.26h-5.66l7.1-9.5z"/>
				</svg>
				<span class="loadSheetTitle">
					Load Sheet
				</span>
			</a>
			<div style="text-align: left">
				<h1>
					Loads - {{ displayedRowCount }}
				</h1>
			</div>

			<!-- The Modal -->
			<div id="myModal" class="modal">
				<!-- Modal content -->
				<div class="form-style-5">
					<!-- <form name="myForm" onsubmit="DoIT()">
						<fieldset>
							<legend>Load Setup</legend>
							Total Miles <input type="text" name="field1" placeholder="0">
							Total Weight<input type="email" name="field2" placeholder="Enter weight in pounds.">
							Contact information<textarea name="field3" placeholder="Contact information"></textarea>    
						</fieldset>
						<button v-on:click="DoIT()">Apply</button>
					</form> -->
					<form  id="MyForm" @submit.prevent="doIT">
						<fieldset>
							<legend>Load Setup</legend>
							Total Miles <input type="number" id="totalMiles" name="field1" placeholder="0" v-model="formData.totalMiles" required>
							Total Weight<input type="number" id="totalWeight" name="field2" placeholder="Enter weight in pounds." v-model="formData.totalWeight" required>
							Contact information<textarea id="custInfo" name="field3" placeholder="Contact information" v-model="formData.custInfo"></textarea>    
						</fieldset>
						<button v-on:click="DoIT()">Apply</button>
						<!-- <input
							class="submit"
							type="submit"
							value="APPLY"						
						> -->
						<!-- <button type="submit">Add</button> -->
					</form>
				</div>

			</div>
			

		</div>
		<div>
			<AgGridVue
				class="ag-theme-alpine-dark myGrid"
				rowSelection="single"
				animateRows="true"
				@grid-ready="onGridReady"
				@cell-value-changed="getCellChange"
				:rowData="rowData.value"
				:columnDefs="columnDefs.value"
				:gridOptions="gridOptions"
				:context="context"
				:getRowId="getRowId"
				:defaultColDef="defaultColDef"
				:frameworkComponents="frameworkComponents"
				:floatingFilter="true"
				:allowDragFromColumnsToolPanel="false"
			>
			</AgGridVue>
		</div>
	</div>

	<!-- <transition name="bounce" mode="out-in">
    <LoadBoardSet v-if="showModal"></LoadBoardSet>
	</transition> -->


</template>

<script>



	import { reactive } from "vue";
	import "ag-grid-community/styles/ag-grid.css";
	import "ag-grid-community/styles/ag-theme-alpine.css";
	import { AgGridVue } from "ag-grid-vue3";

	import timeSlotRenderer from "../grid/cellRenderers/timeSlotRenderer.vue";
	import booleanCellRenderer from '../grid/cellRenderers/booleanCellRenderer.vue';
	import dateCellRenderer from "../grid/cellRenderers/dateCellRenderer.vue";
	import timeCellRenderer from "../grid/cellRenderers/timeCellRenderer.vue";
	import loadStopsRenderer from '../grid/cellRenderers/loadStopsRenderer.vue';
	import timeCellRendererCustom from '../grid/cellRenderers/timeCellRendererCustom.vue';
	import tenderDateCellRenderer from '../grid/cellRenderers/tenderDateCellRenderer.vue';
	// import LoadBoardSet from "../modals/LoadBoardSet.vue";

	export default {
		components: {
    AgGridVue,
    // LoadBoardSet
},
		props:[
			'data'
		],
		emits:[
			'saveCellChange'
		],
		methods:{
			DoIT(){
				// console.log("BALL!", this.formData.totalMiles, this.formData.totalWeight, this.formData.custInfo, this.formData.loadNo )
				if( this.formData.totalMiles !== 0 && this.formData.totalWeight !== 0 ){
					document.getElementById("myModal").style.display="none";
					this.compileCellChange( this.formData.loadNo, 'totalMiles', this.formData.totalMiles );
					this.compileCellChange( this.formData.loadNo, 'totalWeight', this.formData.totalWeight );
					this.compileCellChange( this.formData.loadNo, 'custInfo', this.formData.custInfo );
					this.compileCellChange( this.formData.loadNo, 'tenderDate', this.formData.tenderDate );
	
					// console.log( "Check :::: ", this.formData.cell );
					this.formData.totalMiles = 0;
					this.formData.totalWeight = 0;
					this.formData.custInfo = '';
					this.formData.tenderDate = '';

				} else {
					alert( "Please fill in the miles and weight!");
				}



			},
			onGridReady(){
				this.gridApi.gridReady = true;
				console.warn( "Grid ready" );
				this.sortGrid();
			},
			refreshData(){
				let params = {
					force: true,
					supressFlash: false
				}
				this.gridApi.refreshCells( params );
			},
			sortGrid(){
				// console.warn( "TEST", this.gridApi.getFilterInstance('status') )
				const filterInstance = this.gridOptions.api.getFilterInstance('status');
				
				filterInstance.setModel({
					type: 'blank',
					filter: ''
				});

				this.gridOptions.api.onFilterChanged();

				this.gridColumnApi.applyColumnState(
					{
						state: [
							{
								colId: 'shipDate', 
								sort: 'desc', 
								sortIndex: 0
							},
							{
								colId: 'timeIn', 
								sort: 'desc', 
								sortIndex: 1
							},
							{
								colId: 'loadNo', 
								sort: 'desc', 
								sortIndex: 2
							}
						],
						defaultState: { sort: null }
					}
				)

			},
			convertDate( date ){
				const d = new Date( date);
				const timeZoneDate = new Date( d.getTime()+d.getTimezoneOffset()*60*1000 );
				const strDate = timeZoneDate.toLocaleDateString();
				const parseDate = strDate.split("/");
				return "" + parseDate[0] + "/" + parseDate[1] + "/" + parseDate[2];
			},
			setOrigShipDate( cell ){
				// console.clear();
				console.warn( "Editing ", cell );
				if( cell.data.origShipDate === "" ){
					cell.data.origShipDate = this.convertDate( cell.data.shipDate );
					this.compileCellChange( cell.data.loadNo, 'origShipDate', cell.data.origShipDate );
				}
			},
			compileCellChange( loadNo, cell, value ){
				var data = {
					loadNo: loadNo,
					cellName: cell,
					value: value
				};
				this.saveCellChange( data );
			},
			getUID( loadNo ){

				String.prototype.hashCode = function(){
					var hash = 0;
					var i = 0;
					var chr = '';

					if( this.length === 0 ) return hash;
					for( i = 0; i < this.length; i++ ){
						chr =this.charCodeAt(i);
						hash = (( hash << 5 ) - hash ) + chr;
						hash |= 0;
					}
					return hash;
				}

				return loadNo.hashCode() + Math.floor(Math.random() * 10000);
			},
			getCellChange( cell ){
				// console.warn( "CELL STUFF", cell );
				var columnName = '';
				var data = '';
				var cellHandler = cell;

				if( cell.currentValue !== undefined ){
					// console.log( "Custom render handling!", cell.currentValue );
					columnName = cell.currentColumn;
					data = Object.assign({}, cell.params.data );
					data[columnName] = cell.currentValue;
					cellHandler = cell.params;
				} else {
					// console.log( "CELL ", cell );
					columnName = cell.column.colId;
					data = data = Object.assign({}, cell.data );
				}

				// console.log( "Cname ", columnName );
				// console.log( "Data: ", data );
				// console.log( "CELL: ", cellHandler );

				// console.log( "Editing ", columnName );
				switch( columnName ){
					case 'shipDate' : {
						if( cellHandler.data.origShipDate === '' ){
							this.compileCellChange( cellHandler.data.loadNo, 'origShipDate', this.convertDate( data[columnName] ) );
						}
						console.log( cellHandler );
						if( cellHandler.data.uid === '' ){
							this.compileCellChange( cellHandler.data.loadNo, 'uid', this.getUID( cellHandler.data.loadNo ) );
						}
					}
					break;
					case 'tenderDate':{
						// console.log( "Fire modal", cell );

						this.formData.loadNo = cellHandler.data.loadNo;
						this.formData.tenderDate = cellHandler.data.tenderDate;
						this.formData.totalMiles = cellHandler.data.totalMiles;
						this.formData.totalWeight = cellHandler.data.totalWeight;
						this.formData.custInfo = cellHandler.data.custInfo;

						// console.log( "Fire modal 2", cellHandler.data.tenderDate );
						if( cellHandler.data.tenderDate === '' ){
							// console.log("Get", cellHandler.data.tenderDate );
							var modal = document.getElementById("myModal");
							document.getElementById("totalMiles").value = cellHandler.data.totalMiles;
							document.getElementById("totalWeight").value = cellHandler.data.totalWeight;
							document.getElementById("custInfo").value = cellHandler.data.custInfo;
							modal.style.display = "block";
							cellHandler.data.tenderDate = data.tenderDate;
							this.formData.tenderDate = cellHandler.data.tenderDate;
						} else{
							console.warn("You cleared the date!", data.tenderDate )
						}
					}
					break;
					case 'timeIn':{
						// console.log( "DATA ", cell );
						if( data[columnName] !== "" ){
							var d = new Date();
							var getDateOnly = d.toISOString().split('T', 1)[0];
							cellHandler.data.shipDate = getDateOnly;
							cellHandler.data.timeIn = data[columnName];
							this.compileCellChange( cellHandler.data.loadNo, 'shipDate', cellHandler.data.shipDate );
							if( cellHandler.data.origShipDate === '' ){
								this.compileCellChange( cellHandler.data.loadNo, 'origShipDate', this.convertDate( cellHandler.data.shipDate ) );
							}
							if( cellHandler.data.uid === '' ){
							this.compileCellChange( cellHandler.data.loadNo, 'uid', this.getUID( cellHandler.data.loadNo ) );
						}
						}else{
							cellHandler.data.timeIn = "";
							cellHandler.data.timeOut = "";
							cellHandler.data.loadTime = "";
							this.compileCellChange( cellHandler.data.loadNo, 'timeIn', cellHandler.data.timeIn );
							this.compileCellChange( cellHandler.data.loadNo, 'timeOut', cellHandler.data.timeOut );
							this.compileCellChange( cellHandler.data.loadNo, 'loadTime', cellHandler.data.loadTime );
							this.compileCellChange( cellHandler.data.loadNo, 'shipDate', cellHandler.data.shipDate );
						}
						this.getLoadTime( cellHandler );
					}
					break;
					case 'timeOut':{
						// console.log( "DATA ", data );
						cellHandler.data.timeOut = data[columnName];
						this.getLoadTime( cellHandler );
						// this.compileCellChange( cellHandler.data.loadNo, 'loadTime', cell.setTime );
					}
					break;
				}
				this.compileCellChange( cellHandler.data.loadNo, columnName, data[columnName] );
			},
			getLoadTime( params ){
				// console.log( "Begin calculating load time. ", params );
				let loadTime = null;
				
				let parseTime = function( t ){
					var d = new Date();
					var time = t.match( /(\d+)(?::(\d\d))?\s*(p?)/ );

					d.setHours( parseInt( time[1]) + (time[3] ? 12 : 0) );
					d.setMinutes( parseInt( time[2]) || 0 );

					return d;
				};

				let formattedNumber = function( number ){
					return number.toLocaleString(
						'en-US',
						{
							minimumIntegerDigits: 2,
							useGrouping: false
						}
					)
				};

				let msToHMS = function( ms ){
					var seconds = ms / 1000;
					var hours = parseInt( seconds / 3600 );
					seconds = seconds % 3600;
					var minutes = parseInt( seconds / 60 );
					seconds = seconds % 60;
					return "" + formattedNumber( hours ) + ":" + formattedNumber( minutes ) + "";
				};

				var timeIn = params.data.timeIn;
				var timeOut = params.data.timeOut;

				if( timeIn !== "" ){
					if( timeOut === "" ){
						var today = new Date();
						timeOut = today.getHours().toString().padStart(2,0) + ":" + today.getMinutes().toString().padStart(2,0) + ":" + today.getSeconds().toString().padStart(2,0);
					}
					loadTime = msToHMS(
						parseTime( timeOut ).getTime() - parseTime( timeIn ).getTime()
					);
				}
				this.compileCellChange( params.data.loadNo, 'loadTime', loadTime );

			},
		},
		setup( props, context ){
			console.log( "Grid setup" );

			const saveCellChange = ( event )=>{
				// console.error("***************");
				// console.error("Saving Cell :: ", event.cellName, " to ", event );
				context.emit( 'saveCellChange', event );
			};

			const rowData = reactive({
				value: props.data
			});
			
			const columnDefs = reactive({
				value: [

					{
						headerName: "Row",
						valueGetter: "node.rowIndex + 1",
						width: 40
					},
					{
						headerName: "Load",
						field: "loadNo",
						filter: 'agTextColumnFilter'
					},
					
					{
						headerName: "Customer",
						field: "customer",
					},
					{
						headerName: "Final Stop",
						field: "name",
						valueGetter: params => params.data.finalStopName + " (" + params.data.finalStopCS + ")",
						width: 460,
					},
					{
						headerName: "Due Date",
						field: "dueDate",
						filter: 'agDateColumnFilter',
						filterParams: {
							comparator: ( dataFromFilter, cellValue ) =>{
								if( cellValue == null ){ return 0; }
								const dateParts = cellValue.split("-");
								const day = Number( dateParts[0] );
								const month = Number( dateParts[1] ) - 1;
								const year = Number( dateParts[2] );
								const cellDate = new Date( year, month, day );
								if( cellDate < dataFromFilter ){
									return -1;
								} else if( cellDate > dataFromFilter ){
									return 1;
								}
								return 0;
							}
						}
					},
					{
						headerName: "Ready Date",
						field: "readyDate",
						filter: 'agDateColumnFilter',
						editable: false,
						filterParams: {
							comparator: ( dataFromFilter, cellValue ) =>{
								if( cellValue == null ){ return 0; }
								const dateParts = cellValue.split("-");
								const day = Number( dateParts[0] );
								const month = Number( dateParts[1] ) - 1;
								const year = Number( dateParts[2] );
								const cellDate = new Date( year, month, day );
								if( cellDate < dataFromFilter ){
									return -1;
								} else if( cellDate > dataFromFilter ){
									return 1;
								}
								return 0;
							}
						},
						cellRenderer: 'dateCellRenderer'
					},
					{
						headerName: "Orig Ship Date",
						field: "origShipDate",
						filter: 'agDateColumnFilter',
						editable: false,
						filterParams: {
							comparator: ( dataFromFilter, cellValue ) =>{
								if( cellValue == null ){ return 0; }
								const dateParts = cellValue.split("-");
								const day = Number( dateParts[0] );
								const month = Number( dateParts[1] ) - 1;
								const year = Number( dateParts[2] );
								const cellDate = new Date( year, month, day );
								if( cellDate < dataFromFilter ){
									return -1;
								} else if( cellDate > dataFromFilter ){
									return 1;
								}
								return 0;
							}
						}
					},
					{
						headerName: "Ship Date",
						field: "shipDate",
						filter: 'agDateColumnFilter',
						editable: false,
						filterParams: {
							comparator: ( dataFromFilter, cellValue ) =>{
								if( cellValue == null ){ return 0; }
								const dateParts = cellValue.split("-");
								const day = Number( dateParts[0] );
								const month = Number( dateParts[1] ) - 1;
								const year = Number( dateParts[2] );
								const cellDate = new Date( year, month, day );
								if( cellDate < dataFromFilter ){
									return -1;
								} else if( cellDate > dataFromFilter ){
									return 1;
								}
								return 0;
							}
						},
						cellRenderer: 'dateCellRenderer'
					},
					// Change this to 8-4
					{
						headerName: "Time Slot",
						field: "timeSlot",
						cellRenderer: 'timeSlotRenderer',
						editable: true
					},
					// Remove this
					// {
					// 	headerName: "UID",
					// 	field: "uid",
					// 	filter: 'agTextColumnFilter'
					// },
					// {
					// 	headerName: "TruckCarrier",
					// 	field: "truckCarrier",
					// 	editable: true
					// },
					{
						headerName: "Equipment",
						field: "equipment",
						editable: true
					},
					{
						headerName: "Truck Description",
						field: "truck",
						editable: true
					},
					{
						headerName: "Staged",
						field: "staged",
						width: 100,
						suppressMenu: true,
						cellRenderer: 'booleanCellRenderer'
					},
					{
						headerName: "QC",
						field: "qc",
						width: 100,
						suppressMenu: true,
						cellRenderer: 'booleanCellRenderer'
					},
					{
						headerName: "Paperwork",
						field: "paperwork",
						width: 100,
						suppressMenu: true,
						cellRenderer: 'booleanCellRenderer'
					},
					{
						headerName: "Time IN",
						field: "timeIn",
						cellRenderer: 'timeCellRendererCustom'
					},
					{
						headerName: "Time OUT",
						field: "timeOut",
						cellRenderer: 'timeCellRendererCustom'
					},
					{
						headerName: "Load Time",
						field: "loadTime",
						
					},
					{
						headerName: "Status",
						field: "status",
						// hide: true
					},
					
				]
			});

			const defaultColDef = {
				editable: false,
				sortable: true,
				filter: true,
				floatingFilter: true,
				resizable: true,
				minWidth: 100,
				enableCellChangeFlash: true,
			};

			return{
				rowData,
				columnDefs,
				defaultColDef,
				getRowId: null,
				context: null,
				gridOptions: null,
				saveCellChange,
				displayedRowCount: null,
				formData: {
					loadNo: '',
					tenderDate: '',
					totalMiles: 0,
					totalWeight: 0,
					custInfo: ''
				}
			};

		},
		watch:{
			data( nVal ){
				if( nVal !== undefined ){
					const rawObject = JSON.parse(JSON.stringify(nVal));
					this.rowData.value = rawObject;
					var getCount = 0;
					for( let i = 0; i != this.rowData.value.length; i++ ){
						if( this.rowData.value[i]['status'] === '' ){
							getCount = getCount + 1;
						}
					}
					this.displayedRowCount = getCount;
				}
			}
		},
		created(){
			this.getRowId = (params) => {
				return params.data.loadNo;
			};
		},
		beforeMount(){

			this.gridOptions = {
				pageTitle: "Bob",
				getRowStyle: function( params ){
					if( params.data.status === 'removed' ){
						return {
							background: '#ff6666'
						};
					}
				}
			};

			this.formData.loadNo = 666;

			this.context = { componentParent: this };

			this.frameworkComponents = {
				timeSlotRenderer: timeSlotRenderer,
				timeCellRendererCustom: timeCellRendererCustom,
				tenderDateCellRenderer: tenderDateCellRenderer,
				booleanCellRenderer: booleanCellRenderer,
				dateCellRenderer: dateCellRenderer,
				timeCellRenderer: timeCellRenderer,
				loadStopsRenderer: loadStopsRenderer
			}
		},
		mounted(){
			this.gridApi = this.gridOptions.api;	
			this.gridApi.gridReady = false;
			this.gridColumnApi = this.gridOptions.columnApi;

			// var that = this;
			
			// setTimeout( getStatus, 2500 );
			
			// function getStatus(){
			// 	console.log( "Checking for updates" );
			// 	that.$emit( 'getStatus', "socket" );
			// 	setTimeout( pauseFunc, 1000 );
			// }

		}
	}


</script>

<style>
	@import './css/grid.css'
</style>
