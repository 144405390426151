<template>
	<div>
		<input
			type="time"
			v-model="currentTime"
			@change="valueChanged( $event )"
		>
	</div>
</template>

<script>
	export default{
		data: function(){
			return{
				currentColumn: null,
				currentTime: null,
			};
		},
		beforeMount(){
			let columnName = this.params.colDef.field;
			this.currentTime = this.params.node.data[ columnName ];
			this.currentColumn = columnName;
		},
		methods:{
			valueChanged(){
				// console.log( "DATE SET TO :: ", event, this.currentDate );
				let rowID = this.params.rowIndex;
				let rowNode = this.params.api.getDisplayedRowAtIndex( rowID );

				this.params.api.startEditingCell( {
					rowIndex: rowID,
					colKey: this.currentColumn
				} );
				this.params.data[ this.currentColumn ] = this.currentTime;
				rowNode.setDataValue( this.currentColumn, this.currentTime );
				this.params.api.stopEditing();
				this.params.context.componentParent.getCellChange( this.params );
				this.params.context.componentParent.getLoadTime( this.params );
			}
		}
	}
</script>