<template>
	<div v-if=" timeIn !== '' ">
		<input
			type="date"
			v-model="currentValue"
			@change="valueChanged( $event )"
			disabled
		>
	</div>
	<div v-if=" timeIn === '' ">
		<input
			type="date"
			v-model="currentValue"
			@change="valueChanged( $event )"
		>
	</div>
</template>

<script>
	export default{
		data: function(){
			return{
				currentColumn: null,
				currentValue: null,
				timeIn: 0
			};
		},
		
		beforeMount(){
			// console.warn(" DATE TEST :: ", this )
			let columnName = this.params.colDef.field;
			this.currentValue = this.params.node.data[ columnName ];
			this.currentColumn = columnName;
			this.timeIn = this.params.data.timeIn 
		},
		methods:{
			valueChanged(){
				console.log( "DATE SET TO :: ",  this );
				if( this.params.data.timeIn === "" ){
					this.params.context.componentParent.getCellChange( this );
				}

			}
		}
	}
</script>