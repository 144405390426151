<template>
	<div v-if=" currentValue === '' " style="height: 42px;">
		<button id="zButton" class="button-27" role="button" @click="handleTimer()">
			{{ title }}
		</button>
	</div>
	<div v-if=" currentValue !== '' " style="display: flex; ">
		<button class="button-44" role="button" @click="clearTime()">
			X
		</button>
		<div style="display: flex; margin-left: 7px;">
			{{ currentValue }}
		</div>

	</div>
</template>


<script>

	var timeBegan = null;
	var timeStopped = null;
	var stoppedDuration = 0;
	var started = null;
	var running = false;
	var time = '00:00:00.000';

	function start(){
		console.log( "START CLICKED ");
		if( running ){
			console.warn( "Clock already running" );
			return;
		}

		if( timeBegan === null ){
			reset();
			timeBegan = new Date();
			console.warn( "timeBegan is null" );
			console.warn( "Reseting timer and setting timeBegan: ", timeBegan );
		}

		if( timeStopped !== null ){
			stoppedDuration +=( new Date() - timeStopped );
			console.warn( "timeStopped: ", timeStopped );
			console.warn( "stoppedDuration set: ", stoppedDuration );
		}

		started = setInterval( clockRunning, 10 );
		running = true;

		console.warn("STATUS: ", running );
	}

	function stop(){
		running = false;
		timeStopped = new Date();
		clearInterval( started );
		console.warn( "CLOCK STOPPED: " );
		console.warn( "CLOCK: ", time );
	}

	function reset(){
		console.warn( "Reset timer " );
		running = false;
		clearInterval( started );
		stoppedDuration = 0;
		timeBegan = null;
		timeStopped = null;
		time = '00:00:00.000';
	}

	function clockRunning(){
		var currentTime = new Date();
		var timeElapsed = new Date( currentTime - timeBegan - stoppedDuration )
		var hour = timeElapsed.getUTCHours();
		var min = timeElapsed.getUTCMinutes();
		var sec = timeElapsed.getUTCSeconds();
		var ms = timeElapsed.getUTCMilliseconds();

		time = 
			zeroPrefix( hour, 2 ) + ":" +
			zeroPrefix( min, 2 ) + ":" +
			zeroPrefix( sec, 2 ) + "." +
			zeroPrefix( ms, 3 );

		// console.warn( "CLOCK: ", time );
	}

	function zeroPrefix( num, digit ){
		var zero = '';
		for( var i = 0; i < digit; i++ ){
			zero += '0';
		}
		return ( zero + num ).slice( -digit ); 
	}


	export default{
		data: function(){
			return{
				title: null,
				currentColumn: null,
				currentValue: null,
				setTime: time
			};
		},
		beforeMount(){
			let columnName = this.params.colDef.field;
			this.currentValue = this.params.node.data[ columnName ];
			this.currentColumn = columnName;

			switch (columnName) {
				case 'timeIn':
					this.title = 'Clock In'
					break;
				case 'timeOut':
					this.title = 'Clock Out'
					break;
				default:
					break;
			}
		},
		computed:{
			formattedElapsedTime(){
				const date = new Date( null );
				date.setSeconds( this.elapsedTime / 1000 );
				const utc = date.toUTCString();
				console.log( "TIMER : ", utc.substr( utc.indexOf( ":" ) -2, 8 ) )
				return utc.substr( utc.indexOf( ":" ) -2, 8 );
			}
		},
		methods:{
			clearTime(){
				switch ( this.params.column.colId ) {
					case 'timeIn':
						console.log("CLEAR");
						this.currentValue = "";
						
						reset();
						break;
					case 'timeOut':
						console.log("Resume");
						this.currentValue = '';
						start();
						break;
				}
				this.params.context.componentParent.getCellChange( this );
			},
			handleTimer(){
				console.log( "You clicked me ", this.params )
				var today = null;
				switch ( this.params.column.colId ) {
					case 'timeIn':
						console.log("START")
						start();
						break;
					case 'timeOut':
						console.log("STOP")
						stop();
						console.log(" SEND : ", this.time )
						break;
					}
					
				today = new Date();
				this.setTime = time;
				this.currentValue = today.getHours().toString().padStart(2,0) + ":" + today.getMinutes().toString().padStart(2,0) + ":" + today.getSeconds().toString().padStart(2,0);
				this.params.context.componentParent.getCellChange( this );
			}
		}
	}
</script>

<style>
/* <!-- HTML !-->
<button class="button-27" role="button">Button 27</button> */

	/* CSS */
	.button-27 {
		appearance: none;
		background-color: #000000;
		border: 2px solid #1A1A1A;
		border-radius: 15px;
		box-sizing: border-box;
		color: #009fff;
		cursor: pointer;
		display: inline-block;
		font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
		font-size: 16px;
		font-weight: 600;
		line-height: normal;
		margin: 0;
		min-height: 32px;
		min-width: 0;
		outline: none;
		padding: 6px 24px;
		text-align: center;
		text-decoration: none;
		transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		width: 100%;
		height: 60%;
		will-change: transform;
	}

	.button-27:disabled {
		pointer-events: none;
	}

	.button-27:hover {
		box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
		transform: translateY(-2px);
	}

	.button-27:active {
		box-shadow: none;
		transform: translateY(0);
	}

	.button-44 {
		background: #e62143;
		border-radius: 8px;
		box-sizing: border-box;
		color: #fff;
		cursor: pointer;
		/* font-family: Mija,-apple-system,BlinkMacSystemFont,Roboto,"Roboto Slab","Droid Serif","Segoe UI",system-ui,Arial,sans-serif; */
		font-size: 14px;
		font-weight: 700;
		justify-content: center;
		line-height: 14px;
		text-align: center;
		text-decoration: none;
		text-decoration-skip-ink: auto;
		text-shadow: rgba(0, 0, 0, .3) 1px 1px 1px;
		text-underline-offset: 1px;
		transition: all .2s ease-in-out;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		width: auto;
		height: 100%;
		padding: 4px 8px;
		word-break: break-word;
		border: 0;
		margin-top: 10px;
	}

	.button-44:active,
	.button-44:focus {
		border-bottom-style: none;
		border-color: #dadada;
		box-shadow: rgba(0, 0, 0, .3) 0 3px 3px inset;
		outline: 0;
	}

	.button-44:hover {
		border-bottom-style: none;
		border-color: #dadada;
	}

</style>