<template >
	<!-- <SocketComponent @status="getStatus" @clients="getClientCount" @mySocket="getSocket" :settings="settings"></SocketComponent> -->
  <div style="padding:3rem">
    <div style="position: relative; width: 100%; background-color: black;">
      <div style="position: absolute; bottom: 0; right: 0; width: 100px; background-color: black;">
        <div style="left: 100px; font-weight: 600; color: grey; ">
          STATUS:
        </div>
        <div id="status" class="greenBall">
        </div>

      </div>
      <div style="position: absolute; bottom: 0; left: 0; width: 100px; background-color: black;">
        <div style="left: 100px; font-weight: 600; color: grey; ">
          USERS: {{ clients }}
        </div>
        <div>
          <router-link to="/logout">Logout</router-link>
        </div>
      </div>
    </div>

    <div style="background-color: grey;">
      <my-grid
          :data="gridData"
          @getStatus="getStatus"
          @saveCellChange="saveCellChange"
      >
      </my-grid>
    </div>
  </div>

</template>

<script>

	// import SocketComponent from '@/components/socket/SocketComponent.vue';
	import MyGrid from '@/components/grid/MyGrid.vue';
	import { mySocket } from '@/assets/js/socket'
	// import { isProxy, toRaw } from 'vue';

	function verifyCookies(){
		// console.log( localStorage );

		if( localStorage['sessionID'] === undefined ){
			let sessionID = Math.floor( Math.random() * 1000000000 );
			localStorage['sessionID'] = sessionID;
		}

		if( localStorage['sync'] === undefined ){
			let sync = 0;
			localStorage['sync'] = sync;
		}
		
		if( localStorage['socketStatus'] === undefined ){
			localStorage['socketStatus'] = '';
		}
	}

	verifyCookies();

	var settings = {
		socket:{
			port: process.env.VUE_APP_SOCKET_PORT
		},
		name: process.env.VUE_APP_NAME
	};

	var socket = mySocket( settings.socket.port );

	export default {
		data(){

			const gridData = [];

			return { 
				socket: socket,
				connectionStatus: null,
				settings: settings,
				tempData: [],
				gridData: gridData,
				sessionID: null,
				clients: null,
			}
		},
		mounted(){
			

      console.log("loadsheet mounted", this);
			var that = this;
			that.socket.onmessage = function( event ){
				var res = JSON.parse( event.data );
        if(res.type === 'API') {
          that.getLoadSheet();
        }
        else if(res.data && !res.data.success){
          localStorage.removeItem('token');
          that.$router.push('/')
        } else {
          switch (res.data.route) {
            case '/getLoadSheet':
              var loadSheet = res.data.data.loadSheet;
              localStorage.setItem('token', JSON.stringify(res.data.token));
              that.gridData = loadSheet;
              console.log( "Load Sheet loaded." );
              break;
            case '/saveCellChange':
              that.processUpdates( res.data.data );
              localStorage['socketStatus'] = '';
              break;
            case '/getLoadSheetUpdate':
              that.getLoadSheet();
              break;
            default:
              break;
          }
        }

				// console.warn( "Mount eve ", event, res );
			};

			that.socket.onclose = function( event ){
				console.log( "Hmph!", event );
				document.getElementById( 'status' ).className = 'redBall';
				sendPing();

				function sendPing(){
					that.socket = mySocket( settings.socket.port );
					setTimeout( checkStatus, 2500 );
				}

				function checkStatus(){
					switch (that.socket.readyState) {
						case 3:
							setTimeout( sendPing, 1000 );
							break;
						case 1:
							document.getElementById( 'status' ).className = 'greenBall';
							break;
						case 0:
						default:
							break;
					}


				}


			};
			
			that.socket.onerror = function( event ){
				console.log( "ERROR!", event );
				document.getElementById( 'status' ).className = 'redBall';
			};
			
			that.socket.onopen = function( ){
				document.getElementById( 'status' ).className = 'greenBall';
        that.getLoadSheet();
			};

      if(that.socket.readyState === 1){
        this.getLoadSheet();
      }

			// console.log( "GRID DATA:: ", this.gridData );
			// console.log( "CHECK THIS SOCKET ", mySock.socket );

      if(settings.name){
        document.title = settings.name;
      }else{
        document.title = 'Load Sheet';
      }
			// const favicon = document.querySelector('link[rel="icon"]');
			// favicon.remove();

			// let link32 = document.createElement('link');
			// link32.rel = 'icon';
			// link32.type = 'image/png';
			// link32.sizes = '32x32';
			// document.head.appendChild(link32);
			// link32.href = "../assets/favicon-32x32.png"

			// let link16 = document.createElement('link');
			// link16.rel = 'icon';
			// link16.type = 'image/png';
			// link16.sizes = '16x16';
			// document.head.appendChild(link16);
			// link16.href = "../assets/favicon-16x16.png"

			// let link96 = document.createElement('link');
			// link96.rel = 'icon';
			// link96.type = 'image/png';
			// link96.sizes = '96x96';
			// document.head.appendChild(link96);
			// link96.href = "../assets/favicon-96x96.png"


		},
		methods:{
			getSocket( value ){
				console.log( "FIRED", value );
				this.socket = value;
			},
			getStatus( value ){
				console.log( value );
				this.connectionStatus = value;
			},
			getClientCount( value ){
				console.log( "CLIENTS : ", value );
			},
			saveCellChange( event ){
				// console.log("Bloop");
				var that = this;
				if( this.socket.readyState === 1 ){
          const token = JSON.parse(localStorage.getItem('token'));
					var message = {
						type : 'PUT',
						route: '/saveCellChange',
						data: event,
            access_token: token
					};
					localStorage['socketStatus'] = 'sending';
					// console.warn( message );
					that.socket.send( JSON.stringify( message ));
				}

			},
      processUpdates(updates) {
        var that = this;
        console.log("updates", updates);

        if(updates){
          let loadNo = updates.loadNo;
          let cellName = updates.cellName;
          let value = updates.value;

          console.log("Processing update:", loadNo, cellName, value);

          // Find the row in gridData that matches the loadNo
          let index = that.gridData.findIndex(x => x.loadNo === loadNo);

          if (index > -1) {
            // Update the specific cell (column) with the new value
            console.log("Updating gridData for loadNo:", loadNo, "at index:", index);
            that.gridData[index][cellName] = value;

            // Trigger reactivity by updating the gridData reference
            this.gridData = JSON.parse(JSON.stringify(that.gridData));
          } else {
            console.log("No matching loadNo found in gridData for:", loadNo);
          }
        }
      },
      getLoadSheet() {
        console.log("Grab load sheet");
        console.log(this.socket.readyState);
        if (this.socket.readyState === 1) {
          let token;

          try {
            token = JSON.parse(localStorage.getItem('token'));
          } catch (e) {
            console.log(this.$router);
            this.$router.push('/');
            return;
          }

          if (!token) {
            this.$router.push('/');
          } else {
            var message = {
              type: 'GET',
              route: '/getLoadSheet',
              onlyMe: true,
              access_token: token
            };

            console.log("send a message then");
            this.socket.send(JSON.stringify(message));
          }
        }
      },
		},
		watch:{
			socket( nVal, oVal ){
				// console.clear();
				console.log( "Was ", oVal, " but now is ", nVal );
			},
			connectionStatus( nVal ){
				// console.log( "Was ", oVal, " but now is ", nVal );
				if( nVal ){
					document.getElementById( 'status' ).className = 'greenBall';
				} else {
					document.getElementById( 'status' ).className = 'redBall';
				}
			}
			// gridData( nVal, oVal ){
			// 	console.log( "gridData was ", oVal, " but now is ", nVal );
			// }
		},
		components:{
			// SocketComponent: SocketComponent,
			MyGrid: MyGrid,
			// CookieComponent
		}
	}
</script>


<style>
	@import '@/assets/CSS/ball.css';
</style>
