<template>
  <div>
    <input
        type="checkbox"
        v-model="currentValue"
        @change="valueChanged"
    >
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      currentValue: false,
      currentColumn: null
    };
  },
  beforeMount() {
    let columnName = this.params.colDef.field;

    this.currentValue = !!this.params.data[columnName];
    this.currentColumn = columnName;
  },
  methods: {
    valueChanged() {
      this.params.context.componentParent.getCellChange(this);
    }
  }
};
</script>
