<template>
	<div v-if=" currentValue === '' " style="height: 42px;">
		<button class="button-27-t" role="button" @click="getCurrentDate()">
			{{ title }}
		</button>
	</div>
	<div v-if=" currentValue !== '' " style="display: flex; ">
		<button class="button-44-t" role="button" @click="clearDate()">
			X
		</button>
		<div style="display: flex; margin-left: 7px;">
			{{ currentValue }}
		</div>

	</div>
</template>

<script>
	export default{
		data: function(){
			return{
				currentColumn: null,
				currentValue: null,
				title: null
			};
		},
		beforeMount(){
			let columnName = this.params.colDef.field;
			this.currentValue = this.params.node.data[ columnName ];
			this.currentColumn = columnName;
      this.title = 'Set Tender'
		},
		methods:{
			clearDate(){
				console.log("CLEAR");
				this.currentValue = "";
				this.params.context.componentParent.getCellChange( this );
			},
			getCurrentDate(){
				// var today = new Date();
				this.currentValue = new Date().toLocaleDateString();
				this.params.context.componentParent.getCellChange( this );
			}
		}
	}
</script>

<style>
/* <!-- HTML !-->
<button class="button-27" role="button">Button 27</button> */

	/* CSS */
	.button-27-t {
		appearance: none;
		background-color: #000000;
		border: 2px solid #1A1A1A;
		border-radius: 15px;
		box-sizing: border-box;
		color: #6b8ae2;
		cursor: pointer;
		display: inline-block;
		font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
		font-size: 16px;
		font-weight: 600;
		line-height: normal;
		margin: 0;
		min-height: 32px;
		min-width: 0;
		outline: none;
		padding: 6px 24px;
		text-align: center;
		text-decoration: none;
		transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		width: 100%;
		height: 60%;
		will-change: transform;
	}

	.button-27-t:disabled {
		pointer-events: none;
	}

	.button-27-t:hover {
		box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
		transform: translateY(-2px);
	}

	.button-27-t:active {
		box-shadow: none;
		transform: translateY(0);
	}

	.button-44-t {
		background: #e62143;
		border-radius: 8px;
		box-sizing: border-box;
		color: #fff;
		cursor: pointer;
		/* font-family: Mija,-apple-system,BlinkMacSystemFont,Roboto,"Roboto Slab","Droid Serif","Segoe UI",system-ui,Arial,sans-serif; */
		font-size: 14px;
		font-weight: 700;
		justify-content: center;
		line-height: 14px;
		text-align: center;
		text-decoration: none;
		text-decoration-skip-ink: auto;
		text-shadow: rgba(0, 0, 0, .3) 1px 1px 1px;
		text-underline-offset: 1px;
		transition: all .2s ease-in-out;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		width: auto;
		height: 100%;
		padding: 4px 8px;
		word-break: break-word;
		border: 0;
		margin-top: 10px;
	}

	.button-44-t:active,
	.button-44-t:focus {
		border-bottom-style: none;
		border-color: #dadada;
		box-shadow: rgba(0, 0, 0, .3) 0 3px 3px inset;
		outline: 0;
	}

	.button-44-t:hover {
		border-bottom-style: none;
		border-color: #dadada;
	}

</style>