<template>
	<span>
		<select 
			v-model="selected"
			@change="invokeParentMethod"
			style="width: 400px;"
		>
			<option v-if="finalStop !== '' " disabled value=""> > {{ finalStop }}</option>
			<option
				v-for="stop in loadStops" :value="stop" :key="stop"
			>
				{{ stop }}
			</option>
		</select>	 
	</span>
</template>

<script>
	import { toRefs } from 'vue'

	export default{

		setup( props ){

			const { params } = toRefs( props ); 

			// console.log("Check ", params.value );

			// const { value } = params
			let selected = "";
			let finalStop = params.value.data.finalStop;
			// console.warn("Props", props);
			// console.warn(" CHECK THIS ONE TOO :: ", something );
			// console.warn( something[params.data.loadNo]);
			let value = "";
			let something = params.value.something
			let loadStops = []

			// console.warn( selected, finalStop, loadStops, params.value )

			for (let index = 0; index < something[params.value.data.loadNo].length; index++) {
				const element = something[params.value.data.loadNo][index];
				let name = '';
				let city = '';
				let state = '';
				if( element.name !== undefined ) name = element.name;
				if( element.city !== undefined ) city = element.city;
				if( element.state !== undefined ) state = element.state;
				const row = name + " (" + city + "," + state + ")";
				// console.warn("> > > ", row)
				loadStops.push( row );
			}

			// console.warn( "LoadDrops for ", params.value.data.loadNo, ": ", loadStops );

			let loadNo = params.value.data.loadNo
			let options = [
				{ text: loadNo, value: 'loadNo' },
        { text: 'Two', value: 'B' },
        { text: 'Three', value: 'C' }
			]
			return{ options, value, loadNo, something, loadStops, selected, finalStop };
		},
		methods:{
			invokeParentMethod(){
				// let that = this;
				// console.warn(" SELECT => ", this.selected );
				// this.params.context.componentParent.changeFinalDrop( that );
				let rowID = this.params.rowIndex;
				this.params.api.startEditingCell( {
					rowIndex: rowID,
					colKey: this.params.colDef.field
				} );
				this.params.data[ this.params.colDef.field ] = this.selected;
				// console.warn( "CHECK ", this.params.data[ this.params.colDef.field ]);
				let rowNode = this.params.api.getDisplayedRowAtIndex( rowID );
				rowNode.setDataValue( this.params.colDef.field, this.selected );
				// console.warn( "TIME SLOT :: ", this.params );
				this.params.context.componentParent.getCellChange( this.params );
			}
		}
		
	}

</script>

<style lang="scss" scoped></style>