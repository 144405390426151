<template>
	<span>
		<select 
			v-model="currentValue"
			@change="invokeParentMethod"
			style="width: 120px;"
		>
			<!-- <option disabled value="">Time Slot</option> -->
			<option v-if="timeSlot !== '' " disabled value=""> > {{ timeSlot }}</option>
			<option
				v-for="time in timeRanges" :value="time.value" :key="time.id"
			>
				{{ time.text }}
			</option>
		</select>	 
	</span>
</template>

<script>
	export default{
		data(){
			let selected = '';
			let timeSlot = '';
			// console.log( "CHECK E :: ", this.params );
			let timeRanges = [
				{
					text: '8am - 9am',
					value: "08:00-09:00"
				},
				{
					text: '9am - 10am',
					value: "09:00-10:00"
				},
				{
					text: '10am - 11am',
					value: "10:00-11:00"
				},
				{
					text: '11am - 12pm',
					value: "11:00-12:00"
				},
				{
					text: '12pm - 1pm',
					value: "12:00-13:00"
				},
				{
					text: '1pm - 2pm',
					value: "13:00-14:00"
				},
				{
					text: '2pm - 3pm',
					value: "14:00-15:00"
				},
				{
					text: '3pm - 4pm',
					value: "15:00-16:00"
				},
				// {
				// 	text: '8am - 4pm',
				// 	value: "08:00-16:00"
				// },
				// {
				// 	text: '10am - 12pm',
				// 	value: "10:00-12:00"
				// },
				// {
				// 	text: '12pm - 2pm',
				// 	value: "12:00-14:00"
				// },
				// {
				// 	text: '2pm - 4pm',
				// 	value: "14:00-16:00"
				// },

			];

			for (let index = 0; index < timeRanges.length; index++) {
				const element = timeRanges[index];
				// console.log( "ECHO :: ", element );

				for (const [key, value] of Object.entries(element)) {
					// if()
					if( key )
					// console.log(`${key}: ${value}`);
					if( this.params.data.timeSlot === value ){
						timeSlot = element['text'];
					}
				}	
			}

			// console.warn( "TIME SLOT :: ", timeSlot)
			return { 
				selected, 
				timeRanges,
				timeSlot,
				currentValue: null,
				currentColumn: null,
			};
		},
		beforeMount(){
			let columnName = this.params.colDef.field;
			this.currentValue = this.selected;
			this.currentColumn = columnName;
		},
		methods:{
			invokeParentMethod(){
				// console.log("SOMETHIN CHANGED!", this, event );
				this.params.context.componentParent.getCellChange( this );
			}
		}
	}
</script>