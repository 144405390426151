<template>
  <div class="login-container">
    <h1>Login</h1>
    <button class="login-button" @click="signInWithMicrosoft">Sign in with Microsoft</button>
  </div>
</template>

<script>
import { mySocket } from '@/assets/js/socket'

export default {
  name: 'LoginView',
  data() {
    return {
      socket: null,
      authFlow: null,
    };
  },
  methods: {
    signInWithMicrosoft() {
      // Establish WebSocket connection if not already established
      if (!this.socket) {
        const settings = {
          socket: {
            port: process.env.VUE_APP_SOCKET_PORT
          },
          name: process.env.VUE_APP_NAME
        };

        this.socket = mySocket(settings.socket.port);

        this.socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log("THEDATA", data);
          if (data.auth_uri) {
            // Redirect to Microsoft login page
            localStorage.setItem('clientKey', data.clientKey);
            window.location.href = data.auth_uri;
          } else if (data.token) {
            // Handle the token (e.g., save it, redirect to another page)
            console.log('Authentication successful:', data.token);
          } else if (data.error) {
            // Handle errors
            console.error('SSO Error:', data.error);
          }
        };

        this.socket.onopen = () => {
          this.startSSO();
        };

        this.socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
      } else {
        this.startSSO();
      }
    },
    startSSO() {
      // Send the message to start the SSO flow
      this.socket.send(JSON.stringify({ route: '/sso/start' }));
    },
    completeSSO(authResponse) {
      // After redirect, send the auth code back to the server to complete SSO
      this.socket.send(JSON.stringify({
        route: '/sso/complete',
        auth_response: authResponse
      }));
    }
  },
  mounted() {
    // Handle the redirect after Microsoft login
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      this.completeSSO({ code, state });
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding-top: 50px; /* Adjust this value to move the container down or up */
  background-color: #000000;
  overflow: hidden; /* Prevent scrolling */
}

h1 {
  margin-bottom: 20px;
  color: white;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #005a9e;
}
</style>
