import {createRouter, createWebHistory} from 'vue-router'
import LoadSheet from '../views/LoadSheet.vue'
import Login from '../views/LoginView.vue';
import { mySocket } from '@/assets/js/socket'

let socket = null;

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login,
		beforeEnter: (to, from, next) => {
			if(localStorage.getItem('token')){
				//decode the token
				next('/loadSheet')
			} else {
				next();
			}
		}
	},
	{
		path: '/loadSheet',
		name: 'loadSheet',
		component: LoadSheet
	},
	{
		path: '/logout',
		name: 'logout',
		beforeEnter: (to, from, next) => {

			if(localStorage.getItem('token')){
				localStorage.removeItem('token');
				next('/');
			} else {
				next('/');
			}
		}
	},
	{
		path : '/test',
		name : 'test',
		beforeEnter: () => {
			//get token from local storage
			if(localStorage.getItem('token')){
				const token = JSON.parse(localStorage.getItem('token'));
				console.log(token);

				const settings = {
					socket: {
						port: process.env.VUE_APP_SOCKET_PORT
					},
					name: process.env.VUE_APP_NAME
				};

				socket = mySocket(settings.socket.port);

				socket.onopen = () => {
					console.log('WebSocket connection opened in callback route');
					socket.send(JSON.stringify({
						route: '/sso/auth',
						access_token: token
					}));
				};
			}
		}
	},
	{
		path: '/callback',
		name: 'callback',
		beforeEnter: (to, from, next) => {
			// Handle auth code or token
			const authCode = to.query.code;
			const settings = {
				socket: {
					port: process.env.VUE_APP_SOCKET_PORT
				},
				name: process.env.VUE_APP_NAME
			};

			// Ensure WebSocket connection is established before sending the auth response
			if (!socket) {
				socket = mySocket(settings.socket.port);

				socket.onopen = () => {
					console.log('WebSocket connection opened in callback route');
					socket.send(JSON.stringify({
						route: '/sso/complete',
						auth_response: { code: authCode, clientKey: localStorage.getItem('clientKey') }
					}));
				};

				socket.onmessage = (event) => {
					const data = JSON.parse(event.data);
					console.log("callback", data);
					if(data.token){
						localStorage.setItem('token', JSON.stringify(data.token.access_token));
						next('/loadSheet');
					}
				};

				socket.onerror = (error) => {
					console.error('WebSocket error in callback route:', error);
					next('/');  // Redirect back to login on error
				};
			} else {
				console.log('Using existing WebSocket connection in callback route');
				socket.send(JSON.stringify({
					route: '/sso/complete',
					auth_response: { code: authCode, clientKey: localStorage.getItem('clientKey') }
				}));
				// next('/loadSheet');  // Redirect to LoadSheet after handling auth
			}
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
